/* Customizing css: https://www.ag-grid.com/react-data-grid/global-style-customisation-variables/  */

.ag-theme-alpine
 {
    --ag-background-color: #fff;
    --ag-odd-row-background-color: #fff;
    --ag-header-background-color: #fff;
    --ag-borders-critical: solid 1px;
    --ag-borders-secondary: solid 1px;
    --ag-borders: none;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
}

.ag-cell {
    display: flex;
    align-items: center;
    text-align: center;
  }