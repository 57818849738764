/* Colors */
$primary: #02B3C5;
$primaryHover: #09CBDF;
$primaryPressed: #098EDF;
$primaryLight: #d1fbff;
$primaryLight2: #3dd7e6;
$focus: #9fbff8;

$secondary: #334D6E;

$white: #FFFFFF;

$black: #192A3E;
$black-2: #323C47;

$gray: #707683;
$gray-2: #90A0B7;
$gray-3: #C2CFE0;
$gray-4: #babfc7;
$gray-5: #fbfcfd;
$gray-6: #e9ecef;

$red: #F7685B;

$disabled: #C2CFE0;
$pageBackground: #F5F6F8;

/* Spacings */
$spacingSmall: 15px;
$spacingMedium: 20px;
$spacingLarge: 25px;
$spacingXLarge: 30px;
$spacingXXLarge: 50px;

/* Sizes */
$textXXSmall: 10px;
$textXSmall: 11px;
$textSmall: 12px;
$textMedium: 14px;
$textLarge: 16px;
$textXLarge: 20px;
$textXXLarge: 24px;