.contact-form-body {
	background: #FFFF;
	border-radius: 2%;
	box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
	width: 60%;
}

.contact-form-body-user {
	background: #FFFF;
	border-radius: 2%;
	box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
	width: 100%;
}

.contact-form-body-header {
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-between;
}

.grid-body {
	background: #FFFF;
	border-radius: 2%;
	box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
	margin-left: 20px;
	height: 95.5%;
	width: 100%;
}

.grid-body-half {
	background: #FFFF;
	border-radius: 2%;
	box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
	margin-left: 20px;
	height: 95.5%;
	width: 50%;
}

.body {
	display: flex;
	gap: 20px;
}
