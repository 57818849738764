/* Provide sufficient contrast against white background */
html *
{
  font-family: 'Poppins', sans-serif;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

/* default card */
.container-card {
  background:#fff;
  border: 1px solid #d8d8d8;
  border-radius: 2%;
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
}

/* vertical hr */
.vl {
  border-left: 3px solid #b9c2cd;
  height: 30px;
}

.container {
  max-width: 100%!important;
  width: 100%!important;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.main-container {
  display: flex;
  /* justify-content: space-around; */
  /* flex-shrink: 0; */
  height: 101.2%;
  /* width: 100%; */
  background: #F5F6F8;
}

.navbar {
  margin-bottom: 0px!important;
}

.container-content {
  margin: 10px!important;
  width:100%!important;
  background: #F5F6F8;
  /* width:3000px!important; */
}

.react-grid-layout {
  position: relative;
}

/* Header for pages with AG grid */
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 50px;
  align-items: center
}

.header-content {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 30px;
  gap: 100px;
  width: 1000px!important;
  height: 100%;
  align-items: center
}

.header-content-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 30px;
  gap: -10px;
  width: 1000px!important;
  height: 100%;
}

.header-button {
  display: inline-block;
  margin-right: 65px;
  /* margin: left 500px!important; */
}

/* Cell renderers */
.cell-renderer-content {
  display:flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  vertical-align: auto;
}
