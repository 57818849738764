@import "./_variables.scss";

/* Default */
p {
  font-size: $textSmall;
}

a {
  color: $primary;
}

.text-link {
  color: $primary;
  cursor: pointer;
}

/* Navbar */
.navbar {
  z-index: 2;

  .nav-link {
    color: $secondary;
    font-size: $textMedium;
    font-weight: 500;
    font-family: "Poppins";

    &.disabled {
      color: $disabled;
    }

    &.active {
      color: $primary;
    }
  }

  .navbar-toggler {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-profile {
    font-size: 14px;

    .dropdown-item {
      font-size: 12px;
    }
  }
}

/* Sidenav */
.sidebar {
  .menu-label {
    color: $secondary;
    font-size: $textMedium;
    font-weight: 500;
    font-family: "Poppins";


    .disabled {
      color: $disabled;
    }
  }

  .menu-icon {
    color: $primary;
    text-align: center;
  }

  .menu-anchor {
    height: 40px;

    &:hover {
      background-color: $primaryLight;
    }
  }

  .selected {
    color: $primary;
    font-weight: bold;
  }

  .sub-menu-item {
    .menu-anchor {
      background-color: $gray-5;

      &:hover {
        background-color: $primaryLight;
      }
    }
  }
}

.expense-selector {
  height:300px;
}

/* Main Layout */
.main-container {
  min-height: 94.4vh;
}

.layout-container {
  padding: $spacingXLarge;
  width: 100%;
  background: $pageBackground;
}

/* Content Layout */
.content-header-container {
  margin-bottom: $spacingXLarge;

  .content-title {
    color: $primary;
    font-size: $textXLarge;
    font-weight: 500;
    font-family: "Poppins";
  }

  .content-data-count {
    color: $secondary;
    font-size: $textLarge;
    font-weight: 500;
    font-family: "Poppins";
  }

  .content-data-text {
    color: $secondary;
    font-size: $textMedium;
    font-weight: 500;
    font-family: "Poppins";
  }

  .search-input-field-container {
    .input-group-text {
      color: $gray-2;
      background-color: transparent;
      border: none;
      font-size: $textSmall;
      cursor: pointer;
    }

    .search-input-field {
      color: $black;
      background-color: transparent;
      border: none;
      font-size: $textSmall;
    }

    .select-header-text {
      color: $primary;
      font-size: $textXLarge;
      font-weight: 500;
      font-family: "Poppins";
    }
  }

  .balance-filter-container {
    font-size: $textSmall;
    min-height: 0;
    margin: 0;
  }

  .back-button {
    text-decoration: none;
    color: $black;
  }

  .divider {
    border-left: solid 1px;
    border-color: $gray-2;
    height: 25px;
  }

  .icon {
    color: $primary;
  }
}

.content-body-container {
  min-height: 700px;

  .content-section-container {
    background-color: $white;
    padding: $spacingMedium;
    height: 100%;
  }

  .form-title {
    color: $primary;
    font-size: $textLarge;
    font-family: "Poppins";
  }
}

/* Generic Containers */
.small-container {
  background-color: $white;
  width: 400px;
  padding: 35px 50px;

  .title {
    color: $primary;
    font-size: $textXLarge;
    font-family: "Poppins";
  }
}

/* Buttons */
.btn {
  font-size: $textXSmall;
  font-family: "Poppins";
  min-height: 30px;
  width: 90px;

  /* Button Sizes */
  &.btn-xl {
    min-height: 40px;
    width: 240px;
    font-size: $textSmall;
  }

  &.btn-lg {
    min-height: 40px;
    width: 160px;
    font-size: $textSmall;
  }

  &.btn-md {
    min-height: 35px;
    width: 130px;
    font-size: $textSmall;
  }

  &.btn-sm-md {
    min-height: 30px;
    width: 55px;
  }

  &.btn-sm {
    min-height: 30px;
    width: 30px;
  }

  &.btn-split {
    min-height: 30px;
    width: 30px;
    border-radius: 10px;;
  }

  /* Button Themes */
  &.btn-primary {
    background-color: $primary;
    border-color: $primary;
    color: $white;

    &:hover {
      background-color: $primaryHover;
      border-color: $primaryHover;
    }
  }

  &.btn-secondary {
    background-color: $gray-3;
    border-color: $gray-3;
    color: $black;
  }

  /* Button Types */
  &.btn-floating {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    position: fixed;
    bottom: 40px;
    right: 20px;
    font-size: $textLarge;
  }

  &.btn-editor {
    margin: 3px;
    &:focus {
      outline: 3px solid $focus !important;
    }
  }
}

/* Forms */
.form-container {
  .form-label, .form-control, .form-select {
    font-size: $textSmall;
  }

  .form-label {
    font-weight: bold;
  }

  .form-error-message {
    color: $red;
    font-size: $textSmall;
  }

  &.full-form {
    margin: 0px 100px 0px 100px
  }

  .custom-selector {
    font-size: 12px;
    &.quote-selector {
      width: 170px;
    }
  }

  .form-check-label {
    font-size: $textSmall;
  }
}

/* Cell Renderers */
.profile-picture-renderer-container {
  .profile-picture {
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }
}

//Date Picker
.datepicker-field {
  width: 100%!important;
  height:32px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  font-family: "Poppins";
  font-size: $textSmall;
  padding-left: 12px;
  color: $black!important;

  &:disabled {
    background-color: #E9ECEF;
  }
}

.editable-datepicker {
  .react-datepicker-wrapper {
    width: 100%
  }
}

.react-datepicker-wrapper {
  display: block !important;
}

.two-row-label-renderer-container {
  text-align: left;
  line-height: 20px;

  .label1 {
    color: $black;
    font-size: $textSmall;
    font-weight: bold;
    font-family: "Poppins";
  }

  .label2 {
    color: $gray;
    font-size: $textXSmall;
    font-family: "Poppins";
  }
}

.name-and-email-renderer-container {
  text-align: left;
  line-height: 20px;

  .name {
    color: $black;
    font-size: $textSmall;
    font-weight: bold;
    font-family: "Poppins";
  }

  .email {
    color: $gray;
    font-size: $textXSmall;
    font-family: "Poppins";
  }
}

.phone-and-mobile-renderer-container {
  text-align: left;
  line-height: 20px;

  .phone {
    color: $primary;
    font-size: $textXSmall;
    font-family: "Poppins";
  }

  .mobile {
    color: $primary;
    font-size: $textXSmall;
    font-family: "Poppins";
  }

  .label {
    color: $black;
    font-weight: bold;
  }
}

/* Dashboard */
.dashboard-container {
  .dashboard-stats-container {
    .card {
      border: none;

      .stats-icon-container {
        background-color: $primaryLight;
        color: $primary;
        font-size: $textLarge;
        border-radius: 10px;
        height: 50px;
        width: 50px;
      }

      .card-body {
        background-color: $white;
        color: $black;

        .stats-label {
          font-size: $textSmall;
        }

        .stats-count {
          font-size: $textXLarge;
        }
      }
    }
  }
}

/* Settings */
.settings-container {
  .settings-stats-container {
    .card {
      border: none;

      .stats-icon-container {
        background-color: $primaryLight;
        color: $primary;
        font-size: $textLarge;
        border-radius: 10px;
        height: 50px;
        width: 50px;
      }

      .card-body {
        background-color: $white;
        color: $primary;

        &:hover {
          color:$primaryHover
        }

        .stats-label {
          font-size: $textSmall;
        }

        .stats-count {
          font-size: $textXLarge;
        }



      }
    }
  }
}

/* AG Grid customizations */
.no-headers {
  .ag-root .ag-header {
    display: none;
  }
}

.ag-header-cell-resize {
  display: none;
}

.ag-header-cell-text {
  color: $gray;
  font-weight: bold;
  font-size: $textSmall;
}

.ag-cell-value {
  font-size: $textSmall;
}


.ag-cell-inline-editing {
  height: 100%;
}


.ag-layout-normal > .ag-body-viewport.ag-layout-normal {
  overflow-y: overlay;
}

.ag-header-group-cell-label {
  justify-content: center !important;
}

.ag-header-column-separator {
  border: 1px solid black;
}

.grid-column {
  &.top-row {
    background-color: $gray-6;
  }

  &.dirty-row {
    font-weight: 700;
  }
}

.grouped-columns-header .ag-header-group-cell-label {
  border-top: 1px solid $gray-4;
  border-left: 1px solid $gray-4;
  border-right: 1px solid $gray-4;
}

.grouped-columns-value .ag-cell-value {
  border-left: 1px solid $gray-4;
  border-right: 1px solid $gray-4;
}

.payroll-column-header .ag-header-cell-label {
  justify-content: center !important;
}

.payroll-column-value {
  justify-content: center !important;
}

.cell-editor {
  height: 100%!important;
  width: 100%!important;
}

.numeric-cell-editor {
  height: 100%!important;
  width: 100%!important;
  border:none;
  padding-left : 5px;
}

.cell-editor-input {
  height: 100%;
}

.ag-custom-tooltip {
  font-family: "Poppins";
  font-size: $textSmall;
  background-color: $white;
  border: 1px solid $gray-4;
  padding: 5px;
  margin: 1px;
}

//Quotes
.quote-details-container {
  .quote-details {
    font-size: $textSmall;
  }

  .quote-details-container {
    background-color: $white;
    padding: $spacingMedium;
    min-height: 208px;
  }
}

.quote-body-container {
  min-height: 400px;
}

.payroll-footer-container {
  min-height: 0px;
}

.quote-form-container, .quote-footer-container {
  min-height: 0px;
}

.quote-footer {
  font-size: $textSmall;

  .form-control {
    font-size: $textSmall;
  }

  .notes-container {
    white-space: pre-wrap;
  }
}

/* Tooltip */
.tooltip-container {
  max-width: 70vw;
  z-index: 3;
}

/* Non Printable Components */
@media print {
  .non-printable {
    display: none !important;
  }
}

/* Custom viewports stylings */
.min-vh-65 {
  min-height: 65vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.expense-select__value-container{
  height: 40px;
 }

.footer-editor{
  height: 200px;
}

/* react-draft-wysiwyg */
.wysiwyg-wrapper {
  resize: both;
    height: 350px;
    .wysiwyg-editor {
        height: 270px;
        // width: 100%;
    }
}

.rdw-editor-main {
  border: 1px solid #ced4da ;
  border-radius: 0.375rem;
}

/* Spinner */
.spinner-border {
  color: $primary;
}

/* Reactstrap Modal */
.modal-dialog-centered {
  .modal-footer {
    justify-content: center;
  }
}

.modal-header-container {
  margin-bottom: $spacingSmall;
}

/* Misc */
.login-status-icon {
  color: $primary;
  font-size: 30px;
}

.selected-account {
  font-size: $textXXLarge;
  font-weight:700;
  font-family: "Poppins";
}

.dropup {
  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-header {
    font-size: $textMedium;
  }

  .dropdown-item {
    font-size: $textSmall;
  }
}

.dropdown-center, .dropdown, .dropstart {
  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-toggle::before {
    content: none;
  }

  .dropdown-header {
    font-size: $textSmall;
  }

  .dropdown-item {
    font-size: $textSmall;
  }

  .icon {
    color: $primary;
    font-size: $textMedium;
  }
}

.dropdown-menu {
	li {
		position: relative;
	}
	.dropdown-submenu {
		display: none;
		position: absolute;
		right: 97%;
		top: -5px;
	}

	li {
		&:hover {
			.dropdown-submenu {
				display: block;
			}
		}
	}
}

.w-15 {
  width: 15% !important;
}

.not-resizeable {
  resize: none;
}

// Company logo viewer
.logo-viewer {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  height: 150px;
  font-size: 150px;
  max-width: 300px;
}

.disabled-action:active {
  color: $black;
  background-color: $gray-6;
}

.react-select__control.react-select__control--is-disabled {
  background-color: #E9ECEF;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  .react-select__placeholder {
    color: $black;
  }
}

// Focus styling
.btn:focus {
  // outline: auto!important;
  outline: 3px solid $focus!important; // Customize the color as needed
  // box-shadow: 0 0 5px $secondary; // Customize the shadow as needed
}

.daterange-picker {
  width: 100%;
  font-size: $textSmall;

  .react-daterange-picker__wrapper {
    border-radius: 4px;
    border: 1px solid #b3b3b3;
  }

  .react-daterange-picker__inputGroup {
    text-align: center;
  }

  .react-daterange-picker__range-divider {
    text-align: center;
  }

  .react-daterange-picker__button {
    filter: invert(42%) sepia(5%) saturate(476%) hue-rotate(169deg) brightness(90%) contrast(82%);
  }
}

.not-editable {
  color: $gray-4
}

.block-test {

  div {
    width: 110%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }
}

.cell-renderer-date-picker {
  // height: 100%;
  padding-top: 33px;
  padding-bottom: 33px;
  font-weight: 700!important;
}